
  import { inject, ref, defineComponent } from "vue";
  import { apiPost } from "@/api";
  import { ShowSuccess, Toast } from "@/utils/notify";
  
  export default defineComponent({
    name: "TransactionRow",
    props: {
      dt: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const RequestConfirmation = inject("RequestConfirmation");
  
      const loading = ref(false);
  
      const ToggleAdmin = async () => {
        loading.value = true;
        try {
          await apiPost(
            `/admin/user-account/${
              props.dt.type === "BASIC_USER" ? "add-admin" : "remove-admin"
            }`,
            {
              userId: props.dt.id,
            }
          );
          // eslint-disable-next-line vue/no-mutating-props
          props.dt.type =
            props.dt.type === "BASIC_USER" ? "ADMIN_USER" : "BASIC_USER";
          ShowSuccess();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to toggle admin type: " + e.message,
          }).then();
        }
        loading.value = false;
      };
  
      return {
        loading,
        RequestConfirmation,
        ToggleAdmin,
      };
    },
  });
  