
  import HourglassLoader from "@/components/loader/HourglassLoader.vue";
  import Pagination from "@/components/mini/Pagination.vue";
  import CountSelector from "@/components/mini/CountSelector.vue";
  import { onBeforeMount, ref, defineComponent, watch } from "vue";
  import { apiGet } from "@/api";
  import { useRoute } from "vue-router";
  import { Toast } from "@/utils/notify";
  import TransactionRow from "@/views/transactions/constituent/TransactionRow.vue";
  
  export default defineComponent({
    name: "DepositTransaction",
    components: {
      TransactionRow,
      HourglassLoader,
      Pagination,
      CountSelector,
    },
    props: {
      status: {
        type: String,
        default: null, // Null for "All"
      },
   },
    setup(props) {
      const loading = ref(false);
      const data = ref(null);
      const filters = {
        ALL: "All Deposits",
        SUBMITTED: "Submitted/New",
        CANCELED: "Canceled",
        PROCESSING: "Processing",
        DONE: "Done/Success",
        FAILED: "Done/Failed",
      };
      // const status = ref(undefined);
      const route = useRoute();
  
      const RefreshData = async () => {
        loading.value = true;
        try {
          const queryParam = props.status ? `?status=${props.status}` : "";
          const response = await apiGet(
            `/admin/deposits/transactions${queryParam}`,
          );
          data.value = response.data.data;
          Toast.fire({
            icon: "success",
            title: "Transactions Fetched Successfully",
          }).then();
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to fetch data: " + e.message,
          }).then();
        }
        loading.value = false;
      };
  
      onBeforeMount(RefreshData);
      watch(() => props.status, RefreshData);
  
      return {
        RefreshData,
        route,
        data,
        loading,
        filters,
      };
    },
  });
  