import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "nav nav-tabs",
  id: "myTab",
  role: "tablist"
}
const _hoisted_2 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_3 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_4 = {
  class: "tab-content",
  id: "myTabContent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DepositTransaction = _resolveComponent("DepositTransaction")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center py-3" }, [
          _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "System Transactions"),
            _createElementVNode("p", { class: "mb-0" }, "Below are the users transactions")
          ])
        ], -1)),
        _createElementVNode("ul", _hoisted_1, [
          _createElementVNode("li", _hoisted_2, [
            _createElementVNode("button", {
              class: _normalizeClass(["nav-link", { active: _ctx.activeTab === 'all' }]),
              id: "all-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#all",
              type: "button",
              role: "tab",
              "aria-controls": "card",
              "aria-selected": "true",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveTab('all')))
            }, "All", 2)
          ]),
          _createElementVNode("li", _hoisted_3, [
            _createElementVNode("button", {
              class: _normalizeClass(["nav-link", { active: _ctx.activeTab === 'processing' }]),
              id: "processing-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#processing",
              type: "button",
              role: "tab",
              "aria-controls": "processing",
              "aria-selected": "false",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTab('processing')))
            }, "Processing", 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DepositTransaction, {
            status: _ctx.activeTab === 'processing' ? 'PROCESSING' : null
          }, null, 8, ["status"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}