
  import HourglassLoader from "@/components/loader/HourglassLoader.vue";
  
  import { defineComponent, ref } from "vue";
  
  import DepositTransaction from "@/views/transactions/DepositTransactions.vue";
  
  export default defineComponent({
    name: "Transactions",
    components: {
        DepositTransaction,
    },
    setup() {

      const activeTab = ref("all");

      const setActiveTab = (tab: string) => {
        activeTab.value = tab;
      };

      return {
        activeTab,
        setActiveTab,
      };
    },
  });
  